import * as React from "react";
import Box from "@mui/material/Box";
import titleBg from "../../../assets/img/pynk/Title.png";
import {
  Typography,
  Container,
  Paper,
  Stack,
  FormControl,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getCardActivities,
  selectedCard,
  selectedCardId,
} from "../../../redux/pynk/contents";
import "./paypass.scss";

export default function MyPayPass() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth && auth.user);
  const { cardActivities, status_buy_card_collection } = useSelector(
    ({ contents }) => contents && contents
  );

  
  //console.log("cardActivities", cardActivities);
  const [valueCard, setValueCard] = React.useState("");
  const [cards, setCards] = React.useState([]);

  const handleSelectValueCard = (event) => {
    setValueCard(event.target.value);
  };

  const handleSelectedCard = (index, cardId) => {
    dispatch(selectedCard(index));
    dispatch(selectedCardId(cardId));
    history.push("/paypass_program");
  };

  const handleSelectedCardPlaying = (index, cardId) => {
    dispatch(selectedCard(index));
    dispatch(selectedCardId(cardId));
    history.push("/paypass_myprogram");
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCardActivities(user.user_id));
    setCards(JSON.parse(cardActivities[0]?.card_activities || null) || []);
    setValueCard(cardActivities[0]?.card_id || "");
  }, [cardActivities.length, status_buy_card_collection]);

  React.useEffect(() => {
    const cardSelected = cardActivities.filter(
      (item) => item.card_id == valueCard
    );
    if (valueCard !== "") {
      setCards(JSON.parse(cardSelected[0]?.card_activities || null) || []);
    }
  }, [valueCard]);

  React.useEffect(() => {
    if (user == null) {
      window.location.replace("/home");
    }
  }, []);
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Box
        sx={{
          backgroundImage: `url(${titleBg})`,
          height: "100%",
          backgroundSize: "cover",
          paddingTop: "72px",
          paddingBottom: "72px",
        }}
      >
        <Typography
          align="center"
          fontWeight={600}
          sx={{ fontSize: { xs: "42px", sm: "52px" } }}
        >
          <span style={{ color: "#EF60A3" }}>PYNK</span> PAY PASS
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: "#FFF8FB",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            padding: "32px 0",
          }}
        >
          <Stack
            gap={3}
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: { xs: "center", md: "flex-start" },
            }}
          >
            <FormControl sx={{ width: 182 }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={valueCard}
                onChange={handleSelectValueCard}
                defaultValue={"48"}
                size="medium"
              >
                {cardActivities.map((item, index) => (
                  <MenuItem value={item.card_id} key={index}>
                    การ์ดสะสมแต้ม {index + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <button
              className="button_pink"
              style={{
                width: 195,
                height: 44,
              }}
              onClick={() => history.push("/paypass?slideSale=true")}
            >
              <Typography fontWeight={700}>ซื้อการ์ดโปรแกรมเพิ่ม</Typography>
            </button>
          </Stack>
        </Container>
      </Box>

      <Box sx={{ background: "#DDDDDD" }}>
        <Container
          maxWidth="lg"
          sx={{
            paddingTop: "52px",
            paddingBottom: "63px",
          }}
        >
          <Paper sx={{ borderRadius: "16px", padding: "64px 50px" }}>
            <Grid container spacing={3}>
              {cards.map((item, index) => {
                const isIdVideoNull = item.id_video == "null" ? true : false;
                return (
                  <Grid item xs={12} sm={4} md={2.4} key={index}>
                    <Box
                      sx={{
                        display: { xs: "flex", sm: "block" },
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        className="box_program"
                        sx={{
                          border: isIdVideoNull
                            ? "2px dashed #DDDDDD"
                            : "2px solid #ef60a3",
                          width: "167px",
                          height: "100px",
                        }}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                      >
                        <Typography
                          color={isIdVideoNull ? "#DDDDDD" : "#ef60a3"}
                          className="text_pink"
                          fontWeight={700}
                        >
                          {index + 1}
                        </Typography>

                        {isIdVideoNull ? (
                          <button
                            className={
                              isIdVideoNull ? "button_grey_1" : "button_pink"
                            }
                            style={{
                              width: 118,
                              height: 34,
                            }}
                            onClick={() =>
                              handleSelectedCard(index + 1, valueCard)
                            }
                          >
                            <Typography fontWeight={700}>
                              เลือกโปรแกรม
                            </Typography>
                          </button>
                        ) : (
                          <button
                            className={"button_pink_outline"}
                            style={{
                              width: 118,
                              height: 34,
                            }}
                            onClick={() =>
                              handleSelectedCardPlaying(index + 1, valueCard)
                            }
                          >
                            <Typography fontWeight={700}>เล่นต่อ</Typography>
                          </button>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Paper>
        </Container>
      </Box>
    </Box>
  );
}
