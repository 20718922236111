import React, { useEffect, useState } from "react";
import "../../css/shop_order_summary.css";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import check from "../../../../assets/img/pynk/shop/check.png";
import qrcode from "../../../../assets/img/pynk/shop/qrcode.png";
import prompt_pay from "../../../../assets/img/pynk/shop/promptPay.png";
import Visa from "../../../../assets/img/pynk/shop/Visa.png";
import Mastercard from "../../../../assets/img/pynk/shop/Mastercard.png";

function Payment() {
  const history = useHistory();
  const user = useSelector(({ auth }) => (auth ? auth.user : ""));
  const { value_selected_regis } = useSelector(
    ({ contents }) => contents && contents
  );
  const [statusStep, setStatusStep] = useState(0);
  const [statusContinue, setStatusContinue] = useState(1);
  const [selectedPaymentMethod, setselectedPaymentMethod] = useState("");

  const [formData, setFormData] = useState({
    username: user ? user.first_name : "",
    surname: user ? user.last_name : "",
    phone_number: user ? user.phone : "",
    email: user ? user.email : "",
    checked: false,
  });
  const [errors, setErrors] = useState({
    username: "",
    surname: "",
    phone_number: "",
    email: "",
    checked: false,
  });
  const getRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const newRandomString = getRandomString(10); // สุ่มสตริงยาว 10 ตัวอักษร



  console.log("user",);

  useEffect(() => {
    let dataUserPersist = localStorage.getItem('dataUserPersist');
    console.log("dataUserPersist",);
    if (dataUserPersist) {
      setStatusStep(1)
      setselectedPaymentMethod(JSON.parse(dataUserPersist).payment);
    }

  }, [])
  const handleChange = (event) => {
    const { name, type, checked, value } = event.target;

    if (name == "checkbox") {
      setFormData((prevState) => ({
        ...prevState,
        ["checked"]: type == "checkbox" ? checked : checked,
      }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };



  const validate = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.username?.trim()) {
      newErrors.username = "username is required";
      isValid = false;
    }

    if (!formData.surname?.trim()) {
      newErrors.surname = "surname is required";
      isValid = false;
    }

    if (!formData.phone_number?.trim()) {
      newErrors.phone_number = "phone is required";
      isValid = false;
    }

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!formData.email?.trim()) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!emailPattern.test(formData.email)) {
      newErrors.email = "Email format is invalid";
      isValid = false;
    }

    if (formData.checked != true) {
      newErrors.checked = "Checkbox must be checked";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      setStatusStep(1);
    }
  };

  const handleRadioChange = (event) => {
    setselectedPaymentMethod(event);
  };

  const handleSubmit = async (event) => {

    const dataUserPersist = {
      formData,
      value_selected_regis,
      user_id: user.user_id,
      payment: selectedPaymentMethod,
    };
    localStorage.setItem("dataUserPersist", JSON.stringify(dataUserPersist));
    localStorage.setItem("order_id", user.user_id + "-" + newRandomString);



    if (selectedPaymentMethod == 'qr_code') {
      history.replace("/qr_checkout_pynk_play_pass");
    } else {
      history.replace("/cc_token_pynk_pay_pass");
    }
  };

  const customerInformation = () => {
    return (
      <div className="container">
        <div className="box-form-payment">
          <div className="box-row">
            <div className="box-circle">
              <div className="circle-number">
                <span className="circle-span">1</span>
              </div>
              <p className="circle-p">รายละเอียดการสั่งซื้อ</p>
            </div>
            <div className="box-line-placeholder">
              <div className="box-line-pink" />
            </div>
            <div className="box-circle">
              <div className="circle-number-bg">
                <span className="circle-span-disabled">2</span>
              </div>
              <p className="circle-p">ชำระเงิน</p>
            </div>
            <div className="box-line-placeholder line-mr"></div>
            <div className="box-circle">
              <div className="circle-number-bg">
                <span className="circle-span-disabled">3</span>
              </div>
              <p className="circle-p circle-p-none">เสร็จสิ้น</p>
            </div>
          </div>
          <div className="hr-line-white" />
          <div className="col-12  mt-col">
            <div className="row">
              <div className="col-12 col-md-7 order-2 order-md-1 mt-32-576">
                <p className="text-head-order-summary">เลือกวิธีการชำระเงิน</p>
                <form className="mt-32 ">
                  <div className="input-password">
                    <div className="mb-3 row">
                      <div className="col-12 col-md-6">
                        <div className="customer-first-child-r">
                          <p className="text-login-payment">
                            ชื่อ <span className="login-payment-span">*</span>
                          </p>
                          <input
                            type="text"
                            className="input-form-login"
                            placeholder="กรุณาระบุชื่อ"
                            id="username"
                            name="username"
                            value={formData?.username || ""}
                            onChange={handleChange}
                          />
                          {errors.username && (
                            <div className="error-from">{errors.username}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="customer-first-child-l">
                          <p className="text-login-payment">
                            นามสกุล{" "}
                            <span className="login-payment-span">*</span>
                          </p>
                          <input
                            type="text"
                            className="input-form-login"
                            placeholder="กรุณาระบุนามสกุล"
                            id="surname"
                            name="surname"
                            value={formData?.surname || ""}
                            onChange={handleChange}
                          />
                          {errors.surname && (
                            <div className="error-from">{errors.surname}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <div className="col-12 col-md-6">
                        <div className="customer-first-child-r">
                          <p className="text-login-payment">
                            เบอร์โทรศัพท์{" "}
                            <span className="login-payment-span">*</span>
                          </p>
                          <input
                            type="number"
                            className="input-form-login"
                            placeholder="กรุณาระบุเบอร์โทรศัพท์ที่สามารถติดต่อได้"
                            id="phone_number"
                            name="phone_number"
                            value={formData?.phone_number || ""}
                            onChange={handleChange}
                          />

                          {errors.phone_number && (
                            <div className="error-from">
                              {errors.phone_number}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="customer-first-child-l">
                          <p className="text-login-payment">
                            อีเมล <span className="login-payment-span">*</span>
                          </p>
                          <input
                            type="email"
                            className="input-form-login"
                            placeholder="example@mail.com"
                            id="email"
                            name="email"
                            value={formData?.email || ""}
                            onChange={handleChange}
                          />

                          {errors.email && (
                            <div className="error-from">{errors.email}</div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="checkbox"
                        name="checkbox"
                        value={formData.checked}
                        onChange={handleChange}
                      />
                      <label
                        className="text-login-payment condition"
                        htmlFor="flexCheckDefault"
                      >
                        ข้าพเจ้าต้องการรับข่าวสาร, สิทธิพิเศษและโปรโมชัน จาก
                        Pynx ตามที่ระบุไว้ใน นโยบายความเป็นส่วนตัว
                      </label>
                    </div>
                    {errors.checked && (
                      <div className="error-from mt-1">{errors.checked}</div>
                    )}
                    <button onClick={onSubmit} className="btn-buy-payment">
                      ชำระเงิน
                    </button>
                    <p className="text-login-payment">
                      การคลิกที่ปุ่ม ถือว่าคุณได้ยอมรับใน
                      <span className="condition-span">
                        {" "}
                        ข้อกำหนดและเงื่อนไข
                      </span>{" "}
                      และ
                      <span className="condition-span">
                        {" "}
                        นโยบายความเป็นส่วนตัว
                      </span>
                    </p>
                  </div>
                </form>
              </div>
              <div className="col-12 col-md-5 order-1 order-md-2">
                <div className="order-details">
                  <p className="text-head-order-summary">สรุปรายการสั่งซื้อ</p>
                  <div className="text-order between">
                    <p className="col-8">
                      {"PYNK PLAY PASS" + value_selected_regis.day}
                    </p>
                    <p>{value_selected_regis.price} บาท</p>
                  </div>
                  <div className="line-hr-order" />
                  <p className="amount-be-paid between">
                    ยอดที่ต้องชำระ <span>{value_selected_regis.price} บาท</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const orderOetails = () => {
    return (
      <div className="box-form-payment">
        <div className="box-row">
          <div className="box-circle">
            <div className="circle-number-darkest">
              <img src={check} className="check-image" />
            </div>
            <p className="circle-p">รายละเอียดการสั่งซื้อ</p>
          </div>
          <div className="box-line-placeholder">
            <div className="box-line-pink-max" />
          </div>
          <div className="box-circle">
            <div className="circle-number">
              <span className="circle-span">2</span>
            </div>
            <p className="circle-p">ชำระเงิน</p>
          </div>
          <div className="box-line-placeholder line-mr">
            <div className="box-line-pink" />
          </div>
          <div className="box-circle">
            <div className="circle-number-bg">
              <span className="circle-span-disabled">3</span>
            </div>
            <p className="circle-p circle-p-none">เสร็จสิ้น</p>
          </div>
        </div>
        <div className="hr-line-white" />
        <div className="col-12  mt-col">
          <div class="row">
            <div class="col-12 col-md-7 order-2 order-md-1 mt-32-576">
              <p className="text-head-order-summary">เลือกวิธีการชำระเงิน</p>
              <div className="mt-32 ">
                <div className="input-password">
                  <div
                    className="form-payment cursor-pointer"
                    onClick={() => handleRadioChange("qr_code")}
                  >
                    <div class="form-check">
                      <input
                        class="form-check-input2"
                        type="radio"
                        name="payment_method"
                        value="qr_code"
                        checked={selectedPaymentMethod === "qr_code"}
                        onChange={handleRadioChange}
                      />
                      <label
                        class="form-check-label label-prompt_pay"
                        for="flexRadioDefault1"
                      >
                        QR Code/พร้อมเพย์
                      </label>
                      <div className="show-payment">
                        <img src={qrcode} className="" />
                        <img src={prompt_pay} className="" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="form-payment cursor-pointer"
                    onClick={() => handleRadioChange("credit_card")}
                  >
                    <div class="form-check">
                      <input
                        class="form-check-input2"
                        type="radio"
                        name="payment_method"
                        value="credit_card"
                        checked={selectedPaymentMethod === "credit_card"}
                        onChange={handleRadioChange}
                      />
                      <label
                        class="form-check-label label-prompt_pay"
                        htmlFor="flexRadioDefault1"
                      >
                        บัตรเครดิต
                      </label>
                      <div className="show-payment">
                        <img src={Visa} className="" />
                        <img src={Mastercard} className="" />
                      </div>
                    </div>
                  </div>
                  <button
                    className={
                      statusContinue == 0
                        ? "btn-continue-payment"
                        : "btn-buy-payment"
                    }
                    onClick={handleSubmit}
                  >
                    ชำระเงิน
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-5 order-1 order-md-2">
              <div className="order-details">
                <p className="text-head-order-summary  between">
                  รายละเอียดการสั่งซื้อ{" "}
                </p>
                <p className="text-order">
                  {formData.username} {formData.surname}
                </p>

                <p className="text-order">{formData.phone_number}</p>
                <p className="text-order">{formData.email}</p>
                <div className="line-hr-order" />
                <p className="text-head-order-summary">สรุปรายการสั่งซื้อ</p>

                <div className="text-order between">
                  <p className="col-8">
                    {"PYNK PAY PASS " + value_selected_regis.day}
                  </p>
                  <p>{value_selected_regis.price} บาท</p>
                </div>

                <p className="text-order between">
                  ค่าจัดส่ง <span className="text-head-order-summary">ฟรี</span>
                </p>
                <div className="line-hr-order" />
                <p className="amount-be-paid between">
                  ยอดที่ต้องชำระ <span>{value_selected_regis.price} บาท</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="box-order-summary">
      <div className="background-order-summary row">
        <div className="position-relative justify-content">
          {
            statusStep == 0
              ? customerInformation()
              : statusStep == 1
                ? orderOetails()
                : null // promptPay()
          }
        </div>
      </div>
    </div>
  );
}

export default Payment;
