import * as React from "react";
import titleBg from "../../../assets/img/pynk/Title.png";
import { Box } from "@mui/system";
import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import "./paypass.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  buyVideoProgramPynk,
  getCardActivities,
  getVideoAllProgram,
} from "../../../redux/pynk/contents";
import eightWeek_img from "../../../assets/img/pynk/8week_img.png";
import sevenDay_img from "../../../assets/img/pynk/7day_img.png";
import better_img from "../../../assets/img/pynk/better_img.png";
import fitWith_img from "../../../assets/img/pynk/fitwith_img.png";


const play_pass_course = [
  {
    title: '7day_jinny',
    description: '7day_jinny desc',
    image: sevenDay_img,
    index: 0,
  },
  {
    title: '7day_lita',
    description: '7day_lita desc',
    image: sevenDay_img,
    index: 1,
  },
  {
    title: 'bebe_app_high',
    description: 'bebe_app_high desc',
    image: eightWeek_img,
    index: 2,
  },
  {
    title: 'bebe_app_low',
    description: 'bebe_app_low desc',
    image: eightWeek_img,
    index: 3,
  },
  {
    title: 'fit_with_plants',
    description: 'fit_with_plants desc',
    image: fitWith_img,
    index: 4,
  },
  {
    title: 'preem_bettershape',
    description: 'preem_bettershape desc',
    image: better_img,
    index: 5,
  }
];

export default function PayPassProgram() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth && auth.user);
  const { cardAllProgram, index_card, card_id } = useSelector(
    ({ contents }) => contents && contents
  );
  const [values, setValues] = React.useState({
    type: "",
    equipment: "",
    impact: "",
    course: "All",
  });
  const [selectedCourse, setSelectedCourse] = React.useState(play_pass_course);
  console.log(cardAllProgram);
  const handleChangeValues = (e) => {
    const { name, value } = e.target;
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectedProgram = (indexSelect) => {
    const dataIndexSelect = cardAllProgram
      .filter((_, i) => i == indexSelect)
      .flat();
    dispatch(
      buyVideoProgramPynk(
        user.user_id,
        card_id,
        index_card,
        JSON.stringify(dataIndexSelect)
      )
    );
    dispatch(getCardActivities(user.user_id));
    history.replace("/paypass_myprogram");
  };

  React.useEffect(() => {
    dispatch(getVideoAllProgram());
    if (user == null) {
      window.location.replace("/home");
    }

    if (index_card == 0) {
      window.location.replace("/paypass_my");
    }
  }, []);

  const getSelectedCourse = (values) => {
    if (!values.type && !values.impact && !values.equipment && values.course === "All") {
      return play_pass_course;
    }

    if (values.course && values.course !== "All") {
      return play_pass_course.filter((course) => {
        switch (values.course) {
          case 'Bebe Fit Routine Standard':
            return course.title === 'bebe_app_high';
          case 'Bebe Fit Routine Low Impact':
            return course.title === 'bebe_app_low';
          case 'Fit With Plants':
            return course.title === 'fit_with_plants';
          case 'Better Shape in 60days by Preem':
            return course.title === 'preem_bettershape';
          case '7 days with Pilates Ring (Jinny)':
            return course.title === '7day_jinny';
          case '7 days with Pilates Ring (Lita)':
            return course.title === '7day_lita';
          default:
            return [];
        }
      });
    }

    switch (true) {
      case (values.type === 'Circuit Training' && values.impact === 'High Impact' && values.equipment === 'Dumbbell/Water Bottle'):
        return play_pass_course.filter((_, index) => index == 2);
      case (values.type === 'Circuit Training' && values.impact === 'High Impact' && values.equipment === 'Pilates Ring'):
        return play_pass_course.filter((_, index) => index == 0);
      case (values.type === 'Circuit Training' && values.impact === 'Low Impact' && values.equipment === 'empty'):
        return play_pass_course.filter((_, index) => index == 3);
      // case (values.type === 'Circuit Training' && values.impact === 'Low Impact' && values.equipment === 'Towel'):
      //   return play_pass_course.filter((_, index) => index == 2);
      case (values.type === 'Flexibility' && values.impact === 'empty' && values.equipment === 'Towel'):
        return play_pass_course.filter((_, index) => index == 5);
      case (values.type === 'Flexibility' && values.impact === 'empty' && values.equipment === 'Pilates Ring'):
        return play_pass_course.filter((_, index) => index == 1);
      case (values.type === 'Weight Training' && values.impact === 'empty' && values.equipment === 'Dumbbell/Water Bottle'):
        return play_pass_course.filter((_, index) => index == 4);
      default:
        return [];
    }
  }

  React.useMemo(() => {
    setSelectedCourse(getSelectedCourse(values));
  }, [values]);

  const isStep2Disabled = React.useMemo(() => {
    return values.type === '';
  }, [values.type]);

  const isStep3Disabled = React.useMemo(() => {
    return values.impact === '' || values.type === '';
  }, [values.impact, values.type]);

  return (
    <div>
      <Box
        sx={{
          backgroundImage: `url(${titleBg})`,
          height: "100%",
          backgroundSize: "cover",
          paddingTop: "72px",
          paddingBottom: "72px",
        }}
      >
        <Typography
          align="center"
          fontWeight={600}
          sx={{ fontSize: { xs: "42px", sm: "52px" } }}
        >
          <span style={{ color: "#EF60A3" }}>PYNK</span> PLAY PASS
        </Typography>
      </Box>
      <Container
        maxWidth="xl"
        sx={{ marginTop: "64px", marginBottom: "120px" }}
      >
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={3}>
            <Box>
              <Typography fontSize={"18px"}>โปรแกรมล่าสุด</Typography>
              <Typography fontSize={"18px"} sx={{ marginTop: "18px" }}>
                <span style={{ color: "#EF60A3" }}>โปรแกรมทั้งหมด</span>
              </Typography>
            </Box>
            <Divider
              sx={{
                marginTop: "25px",
                marginBottom: "25px",
                background: "#000000",
              }}
            />

            <Box>
              <Typography fontSize={"18px"} color={"#7A7A7A"} >ตัวกรองคอร์สเรียน</Typography>
              <Typography fontWeight={600} sx={{ marginTop: "30px" }}>1. Sort by Type of Exercise</Typography>
              <FormControl fullWidth size="small" sx={{ marginTop: "8px" }} >
                <Select
                  value={values.type}
                  onChange={handleChangeValues}
                  name="type"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="" disabled>
                    เลือกประเภทออกกำลังกาย
                  </MenuItem>
                  <MenuItem value={"Weight Training"}>Weight Training</MenuItem>
                  <MenuItem value={"Circuit Training"}>Circuit Training</MenuItem>
                  <MenuItem value={"Flexibility"}>Flexibility</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ marginTop: "32px" }}>
              <Typography fontWeight={600}>2. Sort by Exercise Impact</Typography>
              <FormControl fullWidth size="small" sx={{ marginTop: "8px" }} >
                <Select
                  value={values.impact}
                  onChange={handleChangeValues}
                  name="impact"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="" disabled>
                    เลือกความเข้มข้น
                  </MenuItem>
                  <MenuItem value={"empty"}>-</MenuItem>
                  <MenuItem value={"High Impact"}>High Impact</MenuItem>
                  <MenuItem value={"Low Impact"}>Low Impact</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ marginTop: "32px" }}>
              <Typography fontWeight={600}>3. Sort by Equipment</Typography>
              <FormControl fullWidth size="small" sx={{ marginTop: "8px" }} >
                <Select
                  value={values.equipment}
                  onChange={handleChangeValues}
                  name="equipment"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="" disabled>
                    เลือกอุปกรณ์
                  </MenuItem>
                  <MenuItem value={"empty"}>-</MenuItem>
                  <MenuItem value={"Bodyweight"}>Bodyweight</MenuItem>
                  <MenuItem value={"Dumbbell/Water Bottle"}>
                    Dumbbell/Water Bottle
                  </MenuItem>
                  <MenuItem value={"Pilates Ring"}>Pilates Ring</MenuItem>
                  <MenuItem value={"Towel"}>Towel</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Divider
              sx={{
                marginTop: "25px",
                marginBottom: "25px",
                background: "#000000",
              }}
            />

            <Box sx={{ marginTop: "32px" }}>
              <Typography fontWeight={600}>Course</Typography>
              <FormControl fullWidth size="small" sx={{ marginTop: "8px" }} >
                <Select
                  value={values.course}
                  onChange={handleChangeValues}
                  name="course"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="All">
                    เลือกตามตัวกรองคอร์สเรียน
                  </MenuItem>
                  <MenuItem value={"Bebe Fit Routine Standard"}>
                    Bebe Fit Routine Standard
                  </MenuItem>
                  <MenuItem value={"Bebe Fit Routine Low Impact"}>
                    Bebe Fit Routine Low Impact
                  </MenuItem>
                  <MenuItem value={"Fit With Plants"}>
                    Fit With Plants
                  </MenuItem>
                  <MenuItem value={"Better Shape in 60days by Preem"}>
                    Better Shape in 60days by Preem
                  </MenuItem>
                  <MenuItem value={"7 days with Pilates Ring (Jinny)"}>
                    7 days with Pilates Ring (Jinny)
                  </MenuItem>
                  <MenuItem value={"7 days with Pilates Ring (Lita)"}>
                    7 days with Pilates Ring (Lita)
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            {/* <Box>
              <Typography fontSize={"18px"}>
                <b>โปรแกรม <span style={{ color: "#EF60A3" }}>ทั้งหมด</span></b>
              </Typography>
              <span style={{ marginTop: 10 }}>ผลลัพธ์การค้นหา {selectedCourse.length} คอร์ส</span>
            </Box> */}
            <Grid
              container
              sx={{
                // marginTop: "32px",
              }}
              spacing={1}
            >
              {selectedCourse.map((item, i) => (
                <Grid item xs={12} sm={4} md={4} lg={4} key={i}>
                  <Card
                    sx={{
                      maxWidth: 240,
                      padding: "18px 0px",
                      marginBottom: "32px",
                    }}
                  >
                    <CardMedia
                      sx={{ height: 140, backgroundSize: "cover" }}
                      image={item.image}
                      title="green iguana"
                    />
                    <CardContent>
                      <Typography gutterBottom fontSize={"16px"}>
                        <b>{item.title}</b>
                      </Typography>
                      <Typography fontSize={"14px"} color="text.secondary">
                        {item.description}
                      </Typography>
                    </CardContent>
                    <Stack flexDirection={"column"} alignItems={"center"}>
                      <Link to={`/paypass_preview?id_video=${item.index}`}>
                        <button
                          className="button_pink_outline"
                          style={{
                            width: 200,
                            height: 44,
                          }}
                        >
                          ดูตัวอย่างโปรแกรม
                        </button>
                      </Link>

                      <button
                        className="button_pink"
                        style={{
                          width: 200,
                          height: 44,
                        }}
                        onClick={() => handleSelectedProgram(item.index)}
                      >
                        เลือกเล่นโปรแกรมนี้
                      </button>
                    </Stack>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
