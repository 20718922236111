import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ReactPlayer from "react-player/lazy";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  background: "rgba(0,0,0,1)",
}));

export default function DialogVideoPreview({
  handleCloseVideo,
  openDialogVideo,
  videoUrl,
}) {
  const [played, setPlayed] = React.useState(0);
  const [isStartCountDown, setIsStartCountDown] = React.useState(false);
  const [time, setTime] = React.useState(30); // 30 seconds

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  React.useEffect(() => {
    if (played > 0) {
      setIsStartCountDown(true);
    }
  }, [played]);

  React.useEffect(() => {
    let interval = null;
    if (time > 0 && isStartCountDown) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }
    if (!openDialogVideo) {
      setTime(30);
    }

    if (time == 0) {
      handleCloseVideo();
      setIsStartCountDown(false);
    }
    return () => {
      clearInterval(interval);
    };
  }, [time, isStartCountDown]);

  return (
    <BootstrapDialog
      onClose={handleCloseVideo}
      aria-labelledby="customized-dialog-title"
      open={openDialogVideo}
      maxWidth="md"
    >
      <DialogTitle
        sx={{ m: 0, p: 3, background: "black", border: "none !important" }}
        id="customized-dialog-title"
      />
      <IconButton
        aria-label="close"
        onClick={() => {
          handleCloseVideo();
          setIsStartCountDown(false);
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
          background: "#FFFFFF",
        }}
      >
        <CloseIcon sx={{ fontSize: 32 }} />
      </IconButton>
      <DialogContent sx={{ background: "black" }}>
        <ReactPlayer
          url={videoUrl}
          controls={true}
          width={"100%"}
          height={"auto"}
          onEnded={() => console.log("test ended")}
          onProgress={(progress) => {
            setPlayed(progress.playedSeconds);
          }}
          onDuration={(duration) => console.log("test onDuration", duration)}
          playsinline
        />
      </DialogContent>

      <div
        style={{
          padding: "0px 32px",
          background: "rgb(239, 96, 163)",
          color: "#FFFFFF",
        }}
      >
        <h3> {formatTime(time)}</h3>
      </div>
    </BootstrapDialog>
  );
}
