import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import * as React from "react";
import "../paypass.scss";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import chipIcon from "../../../../assets/img/pynk/chip.png";
import icon_play from "../../../../assets/img/pynk/icon_play.png";
import titleBg from "../../../../assets/img/pynk/Title.png";
import DialogVideoPreview from "./DialogVideoPreview";
import {
  Timeline,
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  buyVideoProgramPynk,
  getCardActivities,
  getVideoAllProgram,
} from "../../../../redux/pynk/contents";
import {
  convertFormatTime,
  convertSecondsToMinutes,
} from "../../../../helpers/utils";

export default function GeneratePreviewProgram() {
  const { search } = useLocation();
  const history = useHistory();

  const video_id = search.split("=")[1];
  const dispatch = useDispatch();

  const user = useSelector(({ auth }) => auth && auth.user);
  const { cardAllProgram, index_card, card_id } = useSelector(
    ({ contents }) => contents && contents
  );
  const [openDialogVideo, setOpenDialogVideo] = React.useState(false);
  const [previewVIdeos, setPreviewVideos] = React.useState([]);
  const [videoUrl, setVideoUrl] = React.useState("");

  const totalTime =
    previewVIdeos.reduce((acc, val) => {
      return acc + val.duration;
    }, 0) / 60;

  const handleSelectedProgram = () => {
    dispatch(
      buyVideoProgramPynk(
        user.user_id,
        card_id,
        index_card,
        JSON.stringify(previewVIdeos)
      )
    );
    dispatch(getCardActivities(user.user_id));
    console.log("click program");
    history.replace("/paypass_myprogram");
  };

  const handleClickOpenVideo = (url) => {
    setOpenDialogVideo(true);
    setVideoUrl(url);
  };
  const handleCloseVideo = () => {
    setOpenDialogVideo(false);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (user == null) {
      window.location.replace("/home");
    }
    if (index_card == 0) {
      window.location.replace("/paypass_my");
    }
  }, []);

  React.useEffect(() => {
    dispatch(getVideoAllProgram());
    console.log(cardAllProgram);
    console.log(video_id);
    const video_selected = cardAllProgram.filter(
      (_, index) => index == video_id
    );
    setPreviewVideos(video_selected.flat());
  }, [video_id]);

  console.log("card_id", index_card);
  return (
    <div style={{ background: "#DDDDDD", paddingBottom: "60px" }}>
      <Box
        sx={{
          backgroundImage: `url(${titleBg})`,
          height: "100%",
          backgroundSize: "cover",
          paddingTop: "72px",
          paddingBottom: "72px",
        }}
      >
        <Typography
          align="center"
          fontWeight={600}
          sx={{ fontSize: { xs: "42px", sm: "52px" } }}
        >
          <span style={{ color: "#EF60A3" }}>PYNK</span> PAY PASS
        </Typography>
      </Box>

      <Container
        maxWidth={"xl"}
        sx={{
          background: "white",
          paddingLeft: "-0px !important",
          paddingRight: "-0px !important",
        }}
      >
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap={"wrap"}
          sx={{
            height: "102px",
            background: "#FFF8FB",
            padding: "0 23px",
          }}
        >
          <Typography>รวมเวลาฝึกทั้งหมด {totalTime.toFixed(2)} นาที</Typography>
        </Stack>

        <Grid container sx={{ marginTop: "64px" }}>
          <Grid item xs={3} sm={2} md={1}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Typography color={"#ED0876"} fontWeight={500}>
                เริ่มกันเลย
              </Typography>

              {previewVIdeos.map((item, index) => {
                const isLastIndex = index == previewVIdeos.length - 1;
                return (
                  <Timeline sx={{ ml: -5 }} key={index}>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot
                          sx={{
                            width: 30,
                            height: 30,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: "#DDDDDD",
                            mt: isLastIndex ? "0px" : 0.1,
                            ml: isLastIndex ? 1 : 0,
                          }}
                        >
                          <Typography color={"#7A7A7A"}>{index + 1}</Typography>
                        </TimelineDot>
                        {isLastIndex ? (
                          <Typography color={"#ED0876"} fontWeight={500}>
                            สำเร็จ!
                          </Typography>
                        ) : (
                          <TimelineConnector
                            sx={{
                              width: 7,
                              height: { xs: 270, md: 143 },
                              borderRadius: 16,
                              background: "#DDDDDD",
                            }}
                          />
                        )}
                      </TimelineSeparator>
                    </TimelineItem>
                  </Timeline>
                );
              })}
            </Box>
          </Grid>

          <Grid item xs={9} sm={10} md={11}>
            {previewVIdeos.map((item, index) => {
              const minuteLabel =
                item.duration < 20
                  ? convertFormatTime(item.duration)
                  : convertSecondsToMinutes(item.duration);
              return (
                <Grid container sx={{ marginBottom: "48px" }} key={index}>
                  <Grid item xs={12} sm={12} md={4} lg={2.5}>
                    <div
                      style={{
                        backgroundImage: `url(${item.thumbnail})`,
                      }}
                      className="header_img_video"
                    >
                      <img
                        src={icon_play}
                        style={{
                          width: 64,
                          height: 64,
                          cursor: "pointer",
                        }}
                        alt="img"
                        onClick={() => handleClickOpenVideo(item.url3 || "")}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={8} lg={9.5}>
                    <Box
                      sx={{
                        marginLeft: { xs: 0, lg: "48px" },
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <Stack
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={1}
                        sx={{
                          marginTop: { xs: 2, lg: 0 },
                        }}
                      >
                        <AccessAlarmIcon sx={{ color: "#828282" }} />
                        <Typography color={"#828282"}>
                          {minuteLabel} นาที
                        </Typography>
                      </Stack>
                      <Typography
                        color={"#ED0876"}
                        fontSize={"32px"}
                        fontWeight={600}
                      >
                        {item.name}
                      </Typography>
                      <Stack
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={1}
                      >
                        <Typography color={"#828282"}>
                          สัดส่วนที่ได้ :
                        </Typography>
                        <img src={chipIcon} style={{ height: "40px" }} />
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Box
          sx={{
            height: "100%",
            background: "#FCDEEC",
            marginTop: "64px",
            marginBottom: "120px",
          }}
        >
          <Stack
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ padding: "49px 0" }}
          >
            <Link to="/paypass_program">
              <button
                className="button_pink_outline"
                style={{
                  width: 200,
                  height: 44,
                }}
              >
                <Typography>เลือกโปรแกรมใหม่</Typography>
              </button>
            </Link>
            <button
              className="button_pink"
              style={{
                width: 200,
                height: 44,
              }}
              onClick={handleSelectedProgram}
            >
              <Typography>ยืนยัน เลือกโปรแกรมนี้</Typography>
            </button>
          </Stack>
        </Box>
      </Container>

      <DialogVideoPreview
        openDialogVideo={openDialogVideo}
        handleCloseVideo={handleCloseVideo}
        videoUrl={videoUrl}
      />
    </div>
  );
}

