import {
  Avatar,
  Box,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import * as React from "react";
import bestsellerIcon from "../../../assets/img/pynk/bestseller.png";
import icon_circle from "../../../assets/img/pynk/shop/icon-circle.png";
import Slider from "react-slick";
import { Link, useHistory } from "react-router-dom";
import "./paypass.scss";
import eightWeek_img from "../../../assets/img/pynk/8week_img.png";
import twentyHit_img from "../../../assets/img/pynk/20hiit_img.png";
import sevenDay_img from "../../../assets/img/pynk/7day_img.png";
import better_img from "../../../assets/img/pynk/better_img.png";
import getfit_img from "../../../assets/img/pynk/getfir_img.png";
import fitWith_img from "../../../assets/img/pynk/fitwith_img.png";
import { useDispatch, useSelector } from "react-redux";
import {
  buyCardCollectPoints,
  getCardActivities,
  selectedValueRegister,
} from "../../../redux/pynk/contents";

const imgBanner = [
  { src: eightWeek_img },
  { src: twentyHit_img },
  { src: sevenDay_img },
  { src: better_img },
  { src: getfit_img },
  { src: fitWith_img },
];

const priceCard = [
  {
    day: "5 วัน",
    price: "1",
    number_of_days: "5",
    productName: "PYNK PAY PASS",
  },
  {
    day: "7 วัน",
    price: "1",
    number_of_days: "7",
    productName: "PYNK PAY PASS",
  },
  {
    day: "10 วัน",
    price: "1",
    number_of_days: "10",
    productName: "PYNK PAY PASS",
  },
];

export default function PayPassSale() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth && auth.user);
  const { cardActivities, status_buy_card_collection } = useSelector(
    ({ contents }) => contents && contents
  );
  const [selectedValue, setSelectedValue] = React.useState("7");
  const [selectedCard, setSelectedCard] = React.useState(priceCard[1]);
  const contentRef = React.useRef(null);

  const handleSlideRegister = () => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClickSelectBox = (item) => {
    setSelectedCard(item);
    const daysMap = {
      5: "5",
      7: "7",
      10: "10",
    };
    setSelectedValue(daysMap[item?.number_of_days]);
    dispatch(selectedValueRegister(selectedCard));
  };

  const handleChangeValues = (event) => {
    setSelectedValue(event.target.value);
  };

  const PreviousBtn = (props) => {
    const { onClick, currentSlide } = props;
    return (
      <>
        <div className={`previous-btn-pay`} onClick={onClick}>
          <img src={icon_circle} className="icon-previous-btn" />
        </div>
      </>
    );
  };
  const NextBtn = (props) => {
    const { onClick, slideCount, currentSlide } = props;
    return (
      <>
        <div className={`next-btn-pay`} onClick={onClick}>
          <img src={icon_circle} className="icon-next-btn" />
        </div>
      </>
    );
  };

  const settings = {
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 9000,
  };

  const handleRegisterProgram = () => {
    if (user == null || user?.user_id == "") return history.replace("/login");
    history.replace("/paypass_payment");
    dispatch(selectedValueRegister(selectedCard));
  };

  React.useEffect(() => {
    if (contentRef.current && history.location.search == "?slideSale=true") {
      contentRef.current.scrollIntoView({ behavior: "smooth" });
    }
    dispatch(getCardActivities(user && user.user_id));
  }, [user?.user_id]);

  return (
    <div>
      <Box sx={{ background: "#D9D9D9", height: "100%" }}>
        <Box
          sx={{
            paddingTop: { xs: 2, sm: "113px" },
            paddingLeft: { xs: 2, sm: "121px" },
          }}
        >
          <h3
            style={{
              fontSize: 48,
              fontWeight: 900,
            }}
          >
            PYNK PLAY PASS
          </h3>
          <Box sx={{ width: { xs: "100%", sm: "492px" } }}>
            <h3>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the
            </h3>
          </Box>
          <button
            className="button_pink"
            style={{
              width: 240,
              height: 44,
              marginTop: 27,
              marginBottom: 91,
            }}
            onClick={handleSlideRegister}
          >
            สมัครใช้งาน
          </button>
        </Box>
      </Box>

      <Container maxWidth="xl">
        <h3 style={{ marginTop: 88 }}>โปรแกรมออกกำลังกายออนไลน์</h3>
        <Grid container sx={{ marginTop: "48px" }} spacing={3}>
          {imgBanner.map((item, i) => (
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              key={i}
              sx={{ marginBottom: "27px" }}
            >
              <img
                src={item.src}
                alt={item.src}
                style={{ width: "100%", height: 212 }}
              />
            </Grid>
          ))}
        </Grid>

        <Grid
          container
          sx={{ marginTop: { xs: 8, sm: "124px" } }}
          spacing={3}
          ref={contentRef}
        >
          <Grid item xs={12} md={6}>
            <Paper
              sx={{ borderRadius: "16px", padding: "32px", height: "100%" }}
            >
              <h3 style={{ fontSize: 32 }}>สิ่งที่จะได้รับ</h3>
              {[1, 2, 3, 4].map((item, i) => (
                <Box
                  sx={{ marginTop: "33px" }}
                  display={"flex"}
                  gap={2}
                  key={i}
                >
                  <CheckIcon
                    sx={{
                      background: "#EF60A3",
                      color: "#FFFFFF",
                      borderRadius: "50%",
                    }}
                  />

                  <h5 style={{ fontSize: 20 }}>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </h5>
                </Box>
              ))}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ borderRadius: "16px", padding: "32px" }}>
              <FormControl sx={{ width: "100%" }}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={selectedValue}
                  onChange={handleChangeValues}
                >
                  {priceCard.map((item, i) => (
                    <Box
                      sx={{
                        border: "1px solid #F7ABCE",
                        borderRadius: "16px",
                        backgroundColor:
                          selectedValue == item.number_of_days ? "#FCDEEC" : "",
                        marginBottom: "32px",
                        cursor: "pointer",
                      }}
                      key={i}
                      onClick={() => handleClickSelectBox(item)}
                    >
                      <Box sx={{ padding: "26px" }}>
                        {i == 1 && (
                          <div>
                            <img
                              src={bestsellerIcon}
                              alt=""
                              style={{
                                width: 105,
                                height: 35,
                                marginTop: -65,
                              }}
                            />
                          </div>
                        )}
                        <h6
                          style={{ fontSize: 16, marginTop: i == 1 ? -20 : 0 }}
                        >
                          เลือกโปรแกรมออกกำลังกาย
                        </h6>
                        <Stack
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <FormControlLabel
                            value={item.number_of_days}
                            control={
                              <Radio
                                sx={{
                                  color: "#EF60A3",
                                  "&.Mui-checked": {
                                    color: "#EF60A3",
                                  },
                                }}
                              />
                            }
                            label={item.day}
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontSize: { xs: "24px", sm: "32px" },
                                fontWeight: 500,
                              },
                              mt: 1,
                            }}
                          />

                          <Typography
                            sx={{
                              fontSize: { xs: "24px", sm: "32px" },
                              fontWeight: 500,
                            }}
                          >
                            {item.price + " บาท"}
                          </Typography>
                        </Stack>
                      </Box>
                    </Box>
                  ))}
                </RadioGroup>
              </FormControl>
            </Paper>
          </Grid>
        </Grid>
        <Stack
          flexDirection={"row"}
          justifyContent={"center"}
          flexWrap={"wrap"}
          sx={{
            marginTop: "60px",
            marginBottom: "59px",
          }}
        >
          <button
            className={selectedValue == "" ? "button_grey" : "button_pink"}
            style={{ width: 240, height: 44 }}
            onClick={handleRegisterProgram}
            disabled={selectedValue == "" ? true : false}
          >
            สมัครใช้งาน
          </button>
          {cardActivities.length > 0 && (
            <Link to="paypass_my">
              <button
                className="button_pink_outline"
                style={{
                  width: 240,
                  height: 44,
                }}
              >
                เข้าใช้งาน
              </button>
            </Link>
          )}
        </Stack>
      </Container>

      <Box
        sx={{
          background: "#DDDDDD",
          paddingTop: "91px",
          paddingBottom: "108px",
        }}
      >
        <Stack flexDirection={"column"} alignItems={"center"}>
          <Typography
            sx={{
              fontSize: { xs: "26px", sm: "32px" },
              fontWeight: 500,
              padding: { xs: "16px", sm: 0 },
            }}
          >
            เสียงตอบรับจากผู้ใช้โปรแกรมออกกำลังกาย
          </Typography>

          <Rating
            name="read-only"
            value={5}
            readOnly
            sx={{ fontSize: 40, color: "#EF60A3", marginTop: "16px" }}
          />
        </Stack>
        <Container maxWidth="md" sx={{ marginTop: "48px" }}>
          <Slider {...settings}>
            <div>
              <div style={{ width: "99.5%" }}>
                <Paper
                  sx={{
                    padding: "30px",
                    borderRadius: "16px",
                    height: "100%",
                  }}
                >
                  <Stack flexDirection={"row"} gap={2}>
                    <Avatar />
                    <Box>
                      <Typography>Nus</Typography>
                      <Typography>BeBeFitRoutine#12</Typography>
                    </Box>
                  </Stack>
                  <Typography mt={"24px"}>
                    “Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown”
                    “Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown”
                  </Typography>
                </Paper>
              </div>
            </div>
            <div>
              <div style={{ width: "99.5%" }}>
                <Paper
                  sx={{
                    padding: "30px",
                    borderRadius: "16px",
                    height: "100%",
                  }}
                >
                  <Stack flexDirection={"row"} gap={2}>
                    <Avatar />
                    <Box>
                      <Typography>Nus</Typography>
                      <Typography>BeBeFitRoutine#12</Typography>
                    </Box>
                  </Stack>
                  <Typography mt={"24px"}>
                    “Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown”
                    “Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown”
                  </Typography>
                </Paper>
              </div>
            </div>
          </Slider>
        </Container>
      </Box>
    </div>
  );
}
