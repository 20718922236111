import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ReactPlayer from "react-player/lazy";
import { useDispatch, useSelector } from "react-redux";
import { getCardActivities, updatePlayPassPlayTimeVideo } from "../../../../redux/pynk/contents";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  background: "rgba(0,0,0,1)",
}));

export default function DialogVideo({
  handleCloseVideo,
  openDialogVideo,
  videoUrl,
  isAutoPlay,
  videos,
  setVideoUrl,
}) {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth && auth.user);
  const [played, setPlayed] = React.useState(0);

  const { cardActivities, card_id, index_card } = useSelector(
    ({ contents }) => contents && contents
  );

  const cardSelected = cardActivities.filter(
    (item) => item.card_id == card_id
  );
  const jsonData = JSON.parse(cardSelected[0]?.card_activities || "[]");
  const act_id = jsonData.filter((item, i) => i + 1 == index_card)[0]?.id_video;
  const VideoURL = videoUrl;

  const currentIndex = videos.findIndex(
    (item) => item.url3 == VideoURL
  );
  const currentVideo = videos.filter(
    (_, i) => i == currentIndex
  );
  const updatePlayTimeByAutoPlay = () => {
    const isVideoPlayTimeMoreThanZero = currentVideo[0].play_time === 0;
    if (isVideoPlayTimeMoreThanZero) {
      dispatch(updatePlayPassPlayTimeVideo(user.user_id, act_id, currentIndex));
    }
  };
  const handleAutoPlay = () => {
    const currentVideoURL = videoUrl;
    const currentIndex = videos.findIndex(
      (item) => item.url3 == currentVideoURL
    );
    if (currentIndex == -1) return;
    const nextVideo = videos.filter((_, i) => currentIndex + 1 == i);
    const lastVideo = videos.length - 1;
    if (currentIndex == lastVideo) {
      return;
    } else {
      setVideoUrl(nextVideo[0].url3 || "");
    }
  };

  const handleVideoEnd = () => {
    updatePlayTimeByAutoPlay();
    if (isAutoPlay) {
      handleAutoPlay();
    }
  };

  return (
    <BootstrapDialog
      onClose={handleCloseVideo}
      aria-labelledby="customized-dialog-title"
      open={openDialogVideo}
      maxWidth="md"
    >
      <DialogTitle
        sx={{ m: 0, p: 3, background: "black" }}
        id="customized-dialog-title"
      />
      <IconButton
        aria-label="close"
        onClick={handleCloseVideo}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
          background: "#FFFFFF",
        }}
      >
        <CloseIcon sx={{ fontSize: 32 }} />
      </IconButton>
      <DialogContent sx={{ background: "black" }}>
        <ReactPlayer
          playing={isAutoPlay ? true : false}
          url={videoUrl}
          controls={true}
          width={"100%"}
          height={"auto"}
          onEnded={() => handleVideoEnd()}
          onProgress={(progress) => {
            setPlayed(progress.playedSeconds);
          }}
          onDuration={(duration) => console.log("test onDuration", duration)}
          playsinline
        />
      </DialogContent>
    </BootstrapDialog>
  );
}
